import "./Result.css";

export const ResultLoader1 = () => {
  return (
    <>
      <div className="ResultLoader1-background">
        <div className="ResultLoader1-B1">
          <div className="ResultLoader1-B1B1"></div>
          <div className="ResultLoader1-B1B2"></div>
        </div>
        <div className="ResultLoader1-B2"></div>
      </div>
      <div className="ResultLoader1-background">
        <div className="ResultLoader1-B1">
          <div className="ResultLoader1-B1B1"></div>
          <div className="ResultLoader1-B1B2"></div>
        </div>
        <div className="ResultLoader1-B2"></div>
      </div>
      <div className="ResultLoader1-background">
        <div className="ResultLoader1-B1">
          <div className="ResultLoader1-B1B1"></div>
          <div className="ResultLoader1-B1B2"></div>
        </div>
        <div className="ResultLoader1-B2"></div>
      </div>
      <div className="ResultLoader1-background">
        <div className="ResultLoader1-B1">
          <div className="ResultLoader1-B1B1"></div>
          <div className="ResultLoader1-B1B2"></div>
        </div>
        <div className="ResultLoader1-B2"></div>
      </div>
      <div className="ResultLoader1-background">
        <div className="ResultLoader1-B1">
          <div className="ResultLoader1-B1B1"></div>
          <div className="ResultLoader1-B1B2"></div>
        </div>
        <div className="ResultLoader1-B2"></div>
      </div>
    </>
  );
};
