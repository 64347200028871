import "./SectionPageLoader.css";

export const SectionPageLoader1 = () => {
  return (
    <>
      <div className="SectionPageLoader1-background"></div>
      <div className="SectionPageLoader1-background Size1"></div>
      <div className="SectionPageLoader1-background Size2"></div>
      <div className="SectionPageLoader1-background Size3"></div>
    </>
  );
};
